.chatBox {

  width: '80vw';
  height: '80vh';
  gap: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  
}
.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  height: 100%;
  width: 80%;
 
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30%;
  gap: 20px;
  width: 80%;
  color: black;
}
.status1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30%;
  gap: 20px;
  width: 80%;
  color: #FF6600;
  font-weight: bolder;
  font-size: larger;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: black;
  border-radius: 50px;
}
.dot1 {
  height: 10px;
  width: 10px;
  background-color: #FF6600;
  border-radius: 50px;
}
.divider {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30%;
  gap: 20px;
  width: 80%;
  height: 60px;
  
}
.innerdivider {
  height: 60px;
  width: 2px;
  margin-left: 48px;
  background-color: #FF6600;
  
}
.innerdivider1 {
  height: 60px;
  width: 2px;
  margin-left: 48px;
  background-color: black;
  
}


