.chatBox {

  width: '90vw';
  height: '90vh';
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  
}
.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  height: 100%;
  width: 80%;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30%;
  gap: 20px;
  width: 80%;
  color: black;
}
.status1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30%;
  gap: 20px;
  width: 80%;
  color: #FF6600;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: black;
  border-radius: 50px;
}
.dot1 {
  height: 10px;
  width: 10px;
  background-color: #FF6600;
  border-radius: 50px;
}
.divider {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30%;
  gap: 20px;
  width: 80%;
  height: 60px;
  
}
.innerdivider {
  height: 60px;
  width: 2px;
  margin-left: 48px;
  background-color: #FF6600;
  
}
.innerdivider1 {
  height: 60px;
  width: 2px;
  margin-left: 48px;
  background-color: black;
  
}

.input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8%;
  margin-top: 5%;
  gap: 10px;
  width: '100%';
  font-size:large;
  font-family:'Courier New', Courier, monospace;
  font-weight: bold;
}


