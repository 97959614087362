/* src/Toaster.css */
.toaster {
    position: fixed;
    display: flex;
    align-items: center;
    top: 12%;
    left: 20px;
    background-color: red;
    color: #fff;
    padding: 10px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    font-size:larger;
  }
  