

.chatBox {

  width: 100vw;
  height: '90vh';
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
}
.contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: '13px';
  height: '100%';
  width: '100%';
  
}

.rightContainer {
  display: flex;
  
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 8%;
  gap: 20px;
  width: '100%';
}
.bill {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 8%;
  margin-top: 5%;
  gap: 20px;
  width: '100%';
  font-size:x-large;
  font-family:'Courier New', Courier, monospace;
  font-weight: bold;
}
.item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 8%;
  margin-top: 10px;
  gap: 15px;
  width: '100%';
  font-size: medium;
}
.total {
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  
  width: 100vw;
  font-size: large;
  font-family:'Courier New', Courier, monospace;
  font-weight: bold;
}


